import React from "react"
import Img from "gatsby-image"
import { JesRow, HalfCol, FullCol, QuarterCol } from "../components/layout"
import { Col } from "antd"
import { FixedImage } from "../models/gatsby"
import { Iban } from "./iban"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby-plugin-react-i18next"
import {
  SecondaryHeading,
  PrimaryHeading,
  StandardParagraph,
  NavigationLink,
  SmallNavigationLink,
} from "./blocks"
import { useMediaQuerySafe } from "../components/use-mq-safe"
import { Trans } from "react-i18next"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Modal } from "antd"

interface FooterProps {
  jesLogo?: FixedImage
  style?: React.CSSProperties
}

const Footer: React.FC<FooterProps> = ({ style }) => {
  const { t } = useI18next()

  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const { jesLogo } = data
  const colSize = useMediaQuerySafe()
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    setIsMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  const [email, setEmail] = React.useState("")

  const handleSubmit = () => {
    addToMailchimp(
      email,
      { email },
      "https://jes-kulturstifung.us1.list-manage.com/subscribe/post?u=19fef45452829911910718888&amp;id=69206d9a9f"
    ) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "success") {
          setEmail("")
          Modal.success({
            content: (
              <>
                {t(
                  "Vielen Dank das Sie sich bei unseren Newsletter angemeldet haben."
                )}
              </>
            ),
            okButtonProps: {
              style: {
                background: "#ff74a6",
                border: "none",
                color: "white",
              },
            },
          })
        } else {
          if (data.msg.includes("is already subscribed to list")) {
            Modal.info({
              content: <>{t("Diese E-Mail ist bereits registriert.")}</>,
              okButtonProps: {
                style: {
                  background: "#ff74a6",
                  border: "none",
                  color: "white",
                },
              },
            })
          } else {
            Modal.error({
              content: (
                <>
                  {t(
                    "Die Anmeldung war leider nicht erfolgreich. Bitte versuchen Sie es erneut."
                  )}
                </>
              ),
              okButtonProps: {
                style: {
                  background: "#ff74a6",
                  border: "none",
                  color: "white",
                },
              },
            })
          }
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  return (
    <div style={{ ...style }}>
      <JesRow>
        <JesRow
          noGutter
          noPadding
          align="stretch"
          rowStyle={{ padding: "40px" }}
          style={{
            background: "#bcb4aa",
            marginBottom: "180px",
            marginTop: "90px",
          }}
          rowClassName="newsletter-block"
        >
          <HalfCol
            style={{
              paddingRight: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                color: "white",
                fontSize: isMobile ? "40px" : "60px",
              }}
            >
              <Trans i18nKey="footer.newsletter_block_label">NEWSLETTER</Trans>
            </h1>
            <div style={{ flexGrow: 1 }}></div>
            <PrimaryHeading
              style={{ color: "white", marginBottom: isMobile ? "20px" : 0 }}
            >
              <Trans i18nKey="footer.newsletter_block_catchphrase">
                Wenn Sie sich anmelden, informieren wir Sie über aktuelle Themen
                und Aktionen.
              </Trans>
            </PrimaryHeading>
          </HalfCol>
          <HalfCol
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
            }}
            noPaddingMobile
          >
            <JesRow
              noGutter
              noPadding
              align="stretch"
              rowStyle={{
                width: "100%",
                flexDirection: isMobile ? "column" : "row",

                display: "flex",
              }}
            >
              <Col
                style={{
                  paddingRight: isMobile ? "0px" : "15px",
                  marginBottom: isMobile ? "10px" : 0,
                }}
                flex="1"
              >
                <input
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  value={email}
                  type="email"
                  placeholder="E-Mail"
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "0",
                    outline: "none",
                    minHeight: "40px",
                    borderRadius: 0,
                    paddingLeft: "10px",
                  }}
                />
              </Col>
              <Col
                style={{
                  paddingLeft: isMobile ? "0px" : "15px",
                }}
              >
                <button
                  disabled={email.length <= 0}
                  onClick={handleSubmit}
                  style={{
                    width: "100%",
                    background: "#ff74a6",
                    border: "none",
                    color: "white",
                    borderRadius: "0",
                    cursor: "pointer",
                    outline: "none",
                    display: "block",
                    fontSize: isMobile ? "30px" : "40px",
                    lineHeight: isMobile ? "36px" : "46px",
                    fontFamily: "Accord-ExtraBold",
                  }}
                  color="#ff74a6"
                >
                  <Trans i18nKey="footer.newsletter_anmelden">ANMELDEN</Trans>
                </button>
              </Col>
            </JesRow>
            <div style={{ flexGrow: 1 }}></div>
            <br />
            <span
              style={{
                color: "white",
                fontSize: "14px",
                marginTop: "10px",
                display: "block",
              }}
            >
              <Trans i18nKey="footer.newsletter_bottom_legend">
                (Der Newsletter ist kostenlos und jederzeit kündbar. Wir
                speichern Ihre E-Mail Adresse nur um den Newsletter zu
                versenden. Zum{" "}
                <Link
                  to="/datenschutz"
                  style={{
                    fontSize: "inherit",
                    color: "inherit",
                    textDecoration: "underline",
                  }}
                >
                  Datenschutz
                </Link>
                )
              </Trans>
            </span>
          </HalfCol>
        </JesRow>
      </JesRow>
      <JesRow
        style={{
          textAlign: isMobile ? "center" : "left",

          paddingTop: "101px",
          paddingBottom: "80px",
          background: "rgb(239, 233, 228)",
        }}
      >
        <JesRow noGutter noPadding align="top">
          {/* <JesVerticalSpacer /> */}

          <QuarterCol
            style={{
              paddingRight: "15px",
              marginBottom: isMobile && "20px",
            }}
            noPaddingMobile
          >
            <div
              style={{
                maxWidth: "100%",
                width: "175px",
                margin: isMobile ? "auto" : "inherit",
              }}
            >
              <Img fluid={jesLogo.childImageSharp.fluid} />
            </div>
            <FullCol style={{ paddingLeft: 0, marginTop: "30px" }}>
              <SmallNavigationLink to="/impressum">
                <Trans>IMPRESSUM</Trans>
              </SmallNavigationLink>
              <br />
              <SmallNavigationLink to="/datenschutz">
                <Trans>DATENSCHUTZ</Trans>
              </SmallNavigationLink>
            </FullCol>
          </QuarterCol>
          <QuarterCol
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "15px",
              paddingRight: "15px",
              marginBottom: isMobile && "20px",
            }}
            noPaddingMobile
          >
            <NavigationLink noMargin to="/projekte/kuk">
              <Trans i18nKey="projekte_page_title">Projekte</Trans>
            </NavigationLink>
            <NavigationLink noMargin to="/stiftung">
              <Trans i18nKey="stiftung_page_title">Stiftung</Trans>
            </NavigationLink>
            <NavigationLink noMargin to="/presse">
              <Trans i18nKey="presse_page_title">Presse</Trans>
            </NavigationLink>
            <NavigationLink noMargin to="/mitmachen">
              <Trans i18nKey="mitmachen_page_title">Mitmachen</Trans>
            </NavigationLink>
            <NavigationLink noMargin to="/kontakt">
              <Trans i18nKey="kontakt_page_title">Kontakt</Trans>
            </NavigationLink>
          </QuarterCol>
          <QuarterCol
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              marginBottom: isMobile && "20px",
            }}
            noPaddingMobile
          >
            <StandardParagraph
              style={{ color: "#464646", padding: "0px 0px", fontSize: "18px" }}
            >
              JES Kulturstiftung
              <br />
              <Trans>Adolf-Münzer-Straße 9</Trans>
              <br />
              <Trans>86919 Holzhausen am Ammersee</Trans>
            </StandardParagraph>
            <StandardParagraph
              style={{
                padding: !isMobile && "20px 0px",
                color: "#464646",
                fontSize: "18px",
              }}
            >
              +49 (0)8806 924 115
              <br />
              post@jes-kulturstiftung.de
            </StandardParagraph>
          </QuarterCol>
          <QuarterCol
            style={{
              paddingLeft: "15px",
              marginBottom: isMobile && "20px",
            }}
            noPaddingMobile
          >
            <SecondaryHeading>
              <Trans>SPENDENKONTO</Trans>
            </SecondaryHeading>
            <StandardParagraph
              style={{ marginBottom: "20px", color: "#464646" }}
            >
              JES Kulturstiftung <br />
              Sparkasse Landsberg/Diessen
            </StandardParagraph>
            <StandardParagraph style={{ color: "#464646" }}>
              IBAN:
              <br />
              <Iban bold />
            </StandardParagraph>
            <StandardParagraph style={{ color: "#464646" }}>
              <Trans>Verwendungszweck</Trans>: <Trans>Spende</Trans>
            </StandardParagraph>
          </QuarterCol>
        </JesRow>
      </JesRow>
    </div>
  )
}

export default Footer
